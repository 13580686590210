.content {
    text-align: center;

    h1 {
        font-size: 44px;
    }

    .list {
        text-align: center;
        font-size: 30px;
        color: var(--table-text-accent);
    }
}

.config,
.newEvent {
    text-align: center;
    font-size: 30px;
    color: var(--table-text-accent);
    padding-top: 20px;
    cursor: pointer;
}

.newEvent {
    font-size: 20px;
}